<template>
	<div class="flex flex-col flex-1 p-4 lg:px-8 lg:pb-8 lg:pt-60px">
		<div class="flex flex-col w-full mb-4 lg:flex-row lg:justify-between xl:justify-between">
			<div class="flex flex-row mb-5 lg:w-320px h-47px sm:w-full lg:mb-0">
				<div class="relative flex flex-1 border-asp-blue border-3 border-r-4">
					<div v-if="isShowingPuzzleListToolTip" class="absolute z-50 w-full p-4 bg-asp-blue rounded-2xl lg:bottom-16 lg:top-auto top-60px">
						<h4 class="relative text-white bg-transparent">
							「パズルをえらぶ」<br />
							パズルは約1,000枚で完成します。完成したパズルは保管されて、新たなパズルが登場します。このボタンから過去のパズルを見る事ができます。
							<span class="absolute lg:block right-6 arrow-down hidden"></span>
							<span class="absolute block lg:hidden arrow-up"></span>
						</h4>
					</div>
					<select
						ref="puzzleSelect"
						v-model="currentPuzzleId"
						class="w-full pl-4 text-base bg-right-top bg-no-repeat bg-contain rounded-none appearance-none cursor-pointer sm:pl-3 focus:outline-none lg:text-20px text-asp-light-gray bg-asp-light bg-dropdown-blue-square pr-46px text-align-last-center"
						@change="handleChangePuzzle"
						@focusin="handleShowPuzzleListToolTip"
						@focusout="handleHidePuzzleListToolTip"
					>
						<option v-if="isFetchingPuzzle || puzzleList.length === 0" selected value="">
							{{ isFetchingPuzzle ? 'パズル一覧を取得中...' : 'パズル選択' }}
						</option>
						<option v-for="puzzle in puzzleList" :key="puzzle.id" :value="puzzle.id">
							{{ puzzle.puzzle_title }}
						</option>
					</select>
				</div>
			</div>
			<div class="relative flex flex-row lg:w-320px sm:w-full h-47px">
				<div v-if="isShowingPuzzleSearchToolTip" class="absolute z-10 w-full px-4 py-4 bg-asp-blue rounded-2xl bottom-16">
					<h4 class="relative text-white">
						「絵（ピース）をさがす」<br />
						探したいピースのキーワードを入力して、右側の虫眼鏡マーク押して下さい。例：投稿番号、ニックネーム、タイトル又はメッセージの一部ワード
						<span class="absolute block right-5 arrow-down"></span>
					</h4>
				</div>
				<div class="flex flex-1 border-asp-blue border-3">
					<input
						v-model="pieceTextSearch"
						type="text"
						placeholder="検索"
						class="w-full p-4 text-base lg:text-center sm:pl-3 focus:outline-none lg:text-20px text-asp-light-gray bg-asp-light"
						@focusin="handleShowPuzzleSearchToolTip"
						@focusout="handleHidePuzzleSearchToolTip"
						@keydown="handleEndterSearchPiece"
					/>
				</div>
				<div class="flex w-41px h-47px bg-asp-blue">
					<button type="button" class="flex items-center justify-center flex-1 focus:outline-none" @click="handleSearchPieceAddress">
						<img src="@/assets/images/search-white-icon.png" alt="Search Icon" class="w-7" />
					</button>
				</div>
			</div>
		</div>
		<!-- ANCHOR puzzle description -->
		<div v-if="currentPuzzle && !isFetchingPuzzlePieces" class="flex flex-col justify-center items-center sm:flex-row mt-4 mb-4">
			<h2 class=" font-medium text-xl text-center text-asp-blue">
				<p class="mb-1">みんなで【{{ currentPuzzle.puzzle_title }}】マークを作ろう！</p>
				<p>マークは【{{ maxHighPriorityCount }}】ピースでできあがるよ！</p>
			</h2>
		</div>
		<div class="flex flex-col justify-center items-center sm:flex-row my-6">
			<img src="@/assets/images/this-month-theme.png" alt="This month theme" class="w-40 sm:mr-50px sm: ml-5" />
			<h2 class="flex font-bold text-24px text-asp-pink">【 {{ postTheme || 'なし' }} 】</h2>
		</div>
		<div v-if="currentPuzzle?.puzzle_status !== 3" class="hidden mb-23px xl:block">
			<div class="flex justify-center">
				<button type="button" class="flex flex-col items-center justify-center text-white rounded-full w-28 h-28 bg-asp-blue focus:outline-none" @click="handleClickPostButton">
					<img src="@/assets/images/pencil-white-icon.png" alt="Edit Icon" class="mb-1 w-36px mt-10px" />
					<span class="text-xs">絵をアップする</span>
				</button>
			</div>
		</div>
		<p class="text-center mb-4">今の絵のかず【{{ currentHighPriorityCount }}/1024】</p>
		<label for="showLowPieceLayer" class="d-flex items-center mb-2">
			<input id="showLowPieceLayer" type="checkbox" class="form-checkbox rounded focus:outline-none mr-2" @click="toggleLayerLowPriorityPiece" />
			マークの形を見る
		</label>
		<div v-if="isFetchingPuzzlePieces" class="py-10">
			<h2 class="text-2xl flex justify-center">
				<div style="border-top-color:transparent" class="w-12 h-12 border-4 border-blue-400 border-solid rounded-full animate-spin"></div>
			</h2>
		</div>
		<div ref="puzzleDetailWrapper" class="mb-8 puzzle-wrapper-box" @wheel="handleWheelOnPuzzleWrapper">
			<div ref="puzzleDetailZoom" class="grid grid-cols-32 gap-0.5">
				<!-- ANCHOR puzzlepiece -->
				<div v-for="piece in currentPuzzlePieces" :ref="setPuzzlePiecesRef" :key="piece.id" class="relative col aspect-w-1 aspect-h-1">
					<div
						class="absolute top-0 left-0 hidden w-full border-4 pointer-events-none"
						:class="piece.post_flag && piece.undisplay_flag !== 1 && piece.post_delete_flag === 0 ? 'border-asp-00BFFF animate-ping' : ''"
					></div>
					<div
						v-if="piece.post_flag && piece.undisplay_flag !== 1 && piece.post_delete_flag === 0"
						class="z-50 block bg-white border-white cursor-pointer"
						@click="gotoPostDetail($event, piece)"
					>
						<div
							:class="[piece.post_user_type === 2 ? 'border-asp-FFA500 border-1' : '', piece.post_user_type === 3 ? 'border-asp-FFC0CB border-1' : '']"
							class="absolute top-0 left-0 z-40 right-0 bottom-0"
						></div>
						<img
							:src="piece.post_thumbnail"
							:alt="piece.id"
							:class="[piece.priority_flag === false && state.showLayerLowPriorityPiece ? 'opacity-40' : '']"
							class="w-full h-full object-cover object-center bg-asp-black"
						/>
					</div>

					<div v-else-if="piece.post_flag && piece.undisplay_flag === 1 && piece.post_delete_flag === 0" class="z-1">
						<img src="@/assets/images/un_display_piece.png" :alt="piece.id" class="h-21px" />
					</div>
					<div v-if="!piece.post_flag || !piece.priority_flag" class="bg-gray-300"></div>
				</div>
			</div>
		</div>
		<div v-if="currentPuzzle?.puzzle_status !== 3" class="flex justify-center mb-23px xl:hidden">
			<div class="flex justify-center">
				<button class="flex flex-col items-center justify-center text-white rounded-full w-28 h-28 bg-asp-blue focus:outline-none" @click="handleClickPostButton">
					<img src="@/assets/images/pencil-white-icon.png" alt="Edit Icon" class="mb-1 w-36px mt-10px" />
					<span class="text-11px">絵をアップする</span>
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import Panzoom from '@panzoom/panzoom';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { getNowDate, waitFor } from '@/services/utils';
import { useUndisplayUsers } from '@/hooks/query';
import { reactive } from '@vue/reactivity';
import log from 'tailwindcss/lib/util/log';

export default {
	setup() {
		const { data: undisplayUsers } = useUndisplayUsers();
		const state = reactive({
			showLayerLowPriorityPiece: false
		});
		return {
			state,
			undisplayUsers,
			toggleLayerLowPriorityPiece() {
				state.showLayerLowPriorityPiece = !state.showLayerLowPriorityPiece;
			}
		};
	},
	data() {
		return {
			isShowingPuzzleListToolTip: false,
			isShowingPuzzleSearchToolTip: false,
			puzzleDetailZoom: null,
			isFetchingPuzzle: false,
			puzzleList: [],
			currentPuzzleId: '',
			isFetchingPuzzlePieces: false,
			currentPuzzlePieces: [],
			pieceTextSearch: '',
			puzzlePiecesRefs: [],
			postTheme: null,
			isFetchingPostTheme: false,
			checkGotoPostDetail: true,
			countClickPuzzle: 0
		};
	},
	computed: {
		currentPuzzle() {
			return this.puzzleList.find(p => p?.id === this.currentPuzzleId);
		},
		maxHighPriorityCount() {
			return this.currentPuzzlePieces.filter(pp => pp.priority_flag === true).length;
		},
		currentHighPriorityCount() {
			return this.currentPuzzlePieces.filter(pp => !!pp.post_thumbnail).length;
		}
	},
	beforeUpdate() {
		this.puzzlePiecesRefs = [];
	},
	async mounted() {
		await this.handleFetchPuzzleList();
		// const queryActivePuzzle = this.puzzleList.find(puzzle => puzzle.id === this.$route.query.activePuzzleId);
		// console.log(queryActivePuzzle);
		this.currentPuzzleId = localStorage.currentPuzzleId ? localStorage.currentPuzzleId : this.puzzleList[0].id;
		await this.handleFetchPuzzlePiece();
		this.handleFetchPostTheme();
		this.$refs.puzzleDetailZoom.addEventListener('panzoompan', async event => {
			// first render 'panzoompan' run but 'panzoomend' dont so need to check state firstClick puzzle
			// if firstClickPuzzle === 1 it is first render
			// if firstClickPuzzle > 1 it is clicked ( set 2 to prevent number is too large )

			this.countClickPuzzle = this.countClickPuzzle + 1 > 1 ? 2 : 1;
			// console.log('panzoompan detected');
			if (this.countClickPuzzle === 1) {
				this.checkGotoPostDetail = true;
			}
			if (this.countClickPuzzle > 1) {
				this.checkGotoPostDetail = false;
			}
		});

		this.$refs.puzzleDetailZoom.addEventListener('panzoomend', async event => {
			// NOTE wait forr update state in nextTick
			await waitFor(0);
			this.checkGotoPostDetail = true;
		});
	},
	methods: {
		async gotoPostDetail($event, piece) {
			if (this.checkGotoPostDetail === true) {
				this.$router.push({ name: 'posts_detail', params: { id: piece.post_id } });
			}
		},
		handleWheelOnPuzzleWrapper(event) {
			this.puzzleDetailZoom.zoomWithWheel(event);
		},
		handleChangePuzzle() {
			if (this.currentPuzzleId !== '') {
				localStorage.currentPuzzleId = this.currentPuzzleId;
				this.handleFetchPuzzlePiece();
			} else {
				this.currentPuzzlePieces = [];
			}
		},
		async handleFetchPuzzleList() {
			if (this.isFetchingPuzzle) return;
			this.isFetchingPuzzle = true;

			try {
				this.puzzleList = [];

				const puzzleQuerySnapshot = await firebase
					.firestore()
					.collection('m_puzzle')
					.where('publicize_flag', '==', true)
					.where('puzzle_status', 'in', [2, 3])
					.where('delete_flag', '==', false)
					.orderBy('puzzle_number', 'asc')
					.get();
				if (puzzleQuerySnapshot.empty) {
					this.$toast('パズルが登録されていません。運営者にご連絡ください。', {
						type: 'error'
					});
					this.isFetchingPuzzle = false;
					return;
				}

				this.puzzleList = puzzleQuerySnapshot.docs.map(puzzle => {
					return {
						id: puzzle.id,
						...puzzle.data()
					};
				});

				this.puzzleList.sort((a, b) => parseInt(a.puzzle_status, 10) - parseInt(b.puzzle_status, 10));

				this.isFetchingPuzzle = false;
			} catch (error) {
				console.log(error);
				this.$toast('パズル一覧を習得失敗しました。', {
					type: 'error'
				});
				this.isFetchingPuzzle = false;
			}
		},
		async handleFetchPuzzlePiece() {
			if (this.isFetchingPuzzlePieces || this.currentPuzzleId === '') return;
			this.isFetchingPuzzlePieces = true;

			try {
				this.currentPuzzlePieces = [];
				const puzzlePieceQuerySnapshot = await firebase
					.firestore()
					.collection('m_puzzle')
					.doc(this.currentPuzzleId)
					.collection('m_puzzle_piece')
					.orderBy('position_y', 'asc')
					.orderBy('position_x', 'asc')
					.get();

				const data = await firebase
					.firestore()
					.collection('m_puzzle')
					.doc(this.currentPuzzleId)
					.get();

				if (puzzlePieceQuerySnapshot.empty) {
					console.log('No puzzle piece found');
					return;
				}

				this.currentPuzzlePieces = puzzlePieceQuerySnapshot.docs.map(puzzlePieces => {
					return {
						id: puzzlePieces.id,
						...puzzlePieces.data()
					};
				});
				this.puzzleDetailZoom = Panzoom(this.$refs.puzzleDetailZoom, {
					contain: 'outside',
					cursor: 'default',
					maxScale: 8,
					// animate: true,
					minScale: 1
				});
				this.isFetchingPuzzlePieces = false;
			} catch (error) {
				console.log(error);
				this.isFetchingPuzzlePieces = false;
			}
		},
		handleEndterSearchPiece(e) {
			if (e.key === 'Enter' || e.keyCode === 13) {
				this.handleSearchPieceAddress();
			}
		},
		handleSearchPieceAddress() {
			this.currentPuzzlePieces.forEach((piece, index) => {
				this.puzzlePiecesRefs[index].children[0].classList.add('hidden');
			});

			if (this.pieceTextSearch === '') {
				this.$toast('検索条件を入力してください。', {
					type: 'error'
				});

				return;
			}

			if (this.currentPuzzlePieces.length !== 0) {
				let searchResultCount = 0;
				this.currentPuzzlePieces.forEach((piece, index) => {
					if (/^([0-9]+)-([1-2][0-9]|01|02|03|04|05|06|07|08|09|30|31|32)([1-2][0-9]|01|02|03|04|05|06|07|08|09|30|31|32)$/.test(this.pieceTextSearch)) {
						if (piece.id === this.pieceTextSearch.split('-')[1]) {
							this.puzzlePiecesRefs[index].children[0].classList.remove('hidden');
							searchResultCount += 1;
						}
					} else {
						Object.values(piece.search_text ?? {}).forEach(searchText => {
							if (searchText.indexOf(this.pieceTextSearch) !== -1) {
								this.puzzlePiecesRefs[index].children[0].classList.remove('hidden');
								searchResultCount += 1;
							}
						});
					}
				});

				if (searchResultCount === 0) {
					this.$toast('検索結果がありません。', {
						type: 'error'
					});
					return;
				}
				const offsetTop = document.querySelector('.puzzle-wrapper-box').offsetTop;
				window.scrollTo(0, offsetTop - 100);
			}
		},
		setPuzzlePiecesRef(element) {
			if (element) {
				this.puzzlePiecesRefs.push(element);
			}
		},
		handleShowPuzzleListToolTip() {
			if (this.isShowingPuzzleListToolTip) return;
			this.isShowingPuzzleListToolTip = true;
		},
		handleHidePuzzleListToolTip() {
			if (!this.isShowingPuzzleListToolTip) return;
			this.isShowingPuzzleListToolTip = false;
		},
		handleShowPuzzleSearchToolTip() {
			if (this.isShowingPuzzleSearchToolTip) return;
			this.isShowingPuzzleSearchToolTip = true;
		},
		handleHidePuzzleSearchToolTip() {
			if (!this.isShowingPuzzleSearchToolTip) return;
			this.isShowingPuzzleSearchToolTip = false;
		},
		handleClickPostButton() {
			if (!this.puzzleList.find(element => element.puzzle_status === 2)) {
				this.$toast('投稿できるパズルがありません。運営者にご連絡ください。', {
					type: 'error'
				});

				return;
			}

			if (this.currentPuzzleId) {
				this.$router.push({ name: 'posts_create' });
			}
		},
		handleFetchPostTheme() {
			try {
				if (this.isFetchingPostTheme) return;
				const nowDateTime = getNowDate();
				this.isFetchingPostTheme = true;

				firebase
					.firestore()
					.collection('m_theme')
					.where('theme_period_to', '>=', nowDateTime)
					.where('delete_flag', '==', 0)
					.get()
					.then(querySnapshot => {
						if (querySnapshot.empty) return;
						const themeList = querySnapshot.docs.filter(documentSnapshot => {
							return documentSnapshot.data().theme_period_from <= nowDateTime;
						});
						if (themeList.length > 0) {
							this.postTheme = themeList[0].data().theme;
						}
						this.isFetchingPostTheme = false;
					})
					.catch(error => {
						this.$toast('エラーが発生しました。', {
							type: 'error'
						});
						this.isFetchingPostTheme = false;
					});
			} catch (error) {
				this.$toast('エラーが発生しました。', {
					type: 'error'
				});
				this.isFetchingPostTheme = false;
			}
		}
	}
};
</script>

<style scoped>
.animate-red-blink::after {
	content: '';
	width: 21px;
	height: 21px;
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 20;
	border: 2px solid red;
}

.arrow-down {
	width: 0;
	height: 0;
	border-left: 30px solid transparent;
	border-top: 30px solid #1d5fa4;
}

.border-special-user {
	background-image: url('https://i.giphy.com/media/W3UT66oGRc0Z3bPUN8/giphy.webp');
	background-size: cover;
}
.arrow-up {
	width: 0;
	height: 0;
	border-left: 30px solid transparent;
	border-bottom: 30px solid #1d5fa4;
	top: -30px;
	right: 0;
}
.border-asp-00BFFF {
	border-color: rgba(255, 0, 0, var(--tw-border-opacity));
	z-index: 100;
}
.z-1 {
	z-index: 1;
}
@media screen and (max-width: 575px) {
	.border-asp-00BFFF {
		border-color: rgba(255, 0, 0, 0.7);
	}
}
</style>
